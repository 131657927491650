import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { fetchAreaTypesList, fetchBranchList } from '../../../utils/SelectListMethods'
import { hoursOptions, reservationTimeIntervalOptions } from '../../../utils/Constants'
import { useAuth } from '../../auth/core/AuthProvider'

interface AreaEditModalProps {
  itemIdForUpdate: string
  show: boolean
  onClose: () => void
  refresh: Function
}

class Area {
  title?: string
  areaType?: string
  branch?: string
  availableTimeStart?: string // '2024-10-10T08:00:00'
  availableTimeEnd?: string // '2024-10-10T17:00:00'
  reservationTimeInterval?: number
  enabled?: boolean
}

const AreaEditModal: React.FC<AreaEditModalProps> = ({
  itemIdForUpdate,
  show,
  onClose,
  refresh,
}) => {
  const [formData, setFormData] = useState<Area>({
    title: '',
    areaType: '',
    branch: '',
    availableTimeStart: '',
    availableTimeEnd: '',
    reservationTimeInterval: 30,
    enabled: false,
  })

  const [areaTypeOptions, setAreaTypeOptions] = useState<any[]>([])
  const [branchOptions, setBranchOptions] = useState<any[]>([])
  const { token } = useAuth()

  const fetchSingleData = async () => {
    if (itemIdForUpdate !== 'add') {
      axios
        .get(`/admin/areas/${itemIdForUpdate}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setFormData({
            title: response.data.title,
            areaType: response.data.areaType['@id'] || '',
            branch: response.data.branch['@id'] || '',
            availableTimeStart: new Date(response.data.availableTimeStart)
              .toISOString()
              .substring(11, 16),
            availableTimeEnd: new Date(response.data.availableTimeEnd)
              .toISOString()
              .substring(11, 16),
            reservationTimeInterval: response.data.reservationTimeInterval,
            enabled: response.data.enabled,
          })
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
        })
    } else {
      setFormData({
        title: '',
        areaType: '',
        branch: '',
        availableTimeStart: '',
        availableTimeEnd: '',
        reservationTimeInterval: 30,
        enabled: false,
      })
    }
  }

  const updateData = async () => {
    const currentDate = new Date().toISOString().split('T')[0]
    const startTime = new Date(`${currentDate}T${formData.availableTimeStart}:00Z`).toISOString()
    const endTime = new Date(`${currentDate}T${formData.availableTimeEnd}:00Z`).toISOString()

    const updatedData = {
      ...formData,
      availableTimeStart: startTime,
      availableTimeEnd: endTime,
    }

    axios
      .patch(`/admin/areas/${itemIdForUpdate}`, updatedData, {
        headers: {
          Accept: 'application/ld+json',
          'Content-Type': 'application/merge-patch+json',
          Authorization: `Bearer ${localStorage.getItem('@auth/token')}`,
        },
      })
      .then((response) => {
        onClose()
        refresh(true)
      })
      .catch((error) => {
        console.error('Error updating data:', error)
      })
  }

  const createData = async () => {
    const currentDate = new Date().toISOString().split('T')[0]

    const startTime = new Date(`${currentDate}T${formData.availableTimeStart}:00Z`).toISOString()
    const endTime = new Date(`${currentDate}T${formData.availableTimeEnd}:00Z`).toISOString()

    const newData = {
      ...formData,
      availableTimeStart: startTime,
      availableTimeEnd: endTime,
    }

    axios
      .post(`/admin/areas`, newData, {
        headers: {
          Accept: 'application/ld+json',
          'Content-Type': 'application/ld+json',
          Authorization: `Bearer ${localStorage.getItem('@auth/token')}`,
        },
      })
      .then((response) => {
        onClose()
        refresh(true)
        setFormData({
          title: '',
          areaType: '',
          branch: '',
          availableTimeStart: '',
          availableTimeEnd: '',
          reservationTimeInterval: 30,
          enabled: false,
        })
      })
      .catch((error) => {
        console.error('Error creating data:', error)
      })
  }

  const getAreaTypeOptions = async () => {
    fetchAreaTypesList(token ?? '')
      .then((response) => {
        setAreaTypeOptions(response)
      })
      .catch((error) => {
        console.error('Error fetching area types:', error)
      })
  }

  const getBranchOptions = async () => {
    fetchBranchList(token ?? '')
      .then((response) => {
        setBranchOptions(response)
      })
      .catch((error) => {
        console.error('Error fetching branch list:', error)
      })
  }

  useEffect(() => {
    if (itemIdForUpdate !== 'add') {
      fetchSingleData()
    }
    getAreaTypeOptions()
    getBranchOptions()
  }, [itemIdForUpdate])

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === 'reservationTimeInterval' && value != '' ? parseInt(value, 10) : value,
    }))
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (itemIdForUpdate === 'add') {
      createData()
    } else {
      updateData()
    }
  }

  return (
    <Modal show={show} onHide={onClose} backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>{itemIdForUpdate === 'add' ? 'Add Area' : 'Edit Area'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId='formUserTitle'>
            <Form.Label>Title</Form.Label>
            <Form.Control
              type='text'
              name='title'
              value={formData.title}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId='formUserAreaType'>
            <Form.Label>Area Type</Form.Label>
            <Form.Select name='areaType' value={formData.areaType} onChange={handleChange} required>
              <option value=''>Select area type</option>
              {areaTypeOptions.map((areaType) => (
                <option key={areaType.value} value={areaType.value}>
                  {areaType.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group controlId='formUserBranch'>
            <Form.Label>Branch</Form.Label>
            <Form.Select name='branch' value={formData.branch} onChange={handleChange} required>
              <option value=''>Select branch</option>
              {branchOptions.map((branch) => (
                <option key={branch.value} value={branch.value}>
                  {branch.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group controlId='formUserAvailableTimeStart'>
            <Form.Label>Available Time Start</Form.Label>
            <Form.Select
              name='availableTimeStart'
              value={formData.availableTimeStart}
              onChange={handleChange}
              required
            >
              <option value=''>Select start time</option>
              {hoursOptions.map((hour) => (
                <option key={hour.value} value={hour.value}>
                  {hour.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group controlId='formUserAvailableTimeEnd'>
            <Form.Label>Available Time End</Form.Label>
            <Form.Select
              name='availableTimeEnd'
              value={formData.availableTimeEnd}
              onChange={handleChange}
              required
            >
              <option value=''>Select end time</option>
              {hoursOptions.map((hour) => (
                <option key={hour.value} value={hour.value}>
                  {hour.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group controlId='formReservationTimeInterval'>
            <Form.Label>Max Reservation Time (Minute)</Form.Label>
            <Form.Select
              name='reservationTimeInterval'
              value={formData.reservationTimeInterval}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setFormData((prevData) => ({
                  ...prevData,
                  reservationTimeInterval: parseInt(e.target.value, 10),
                }))
              }}
              required
            >
              <option value=''>Select Max Reservation Time</option>
              {reservationTimeIntervalOptions.map((hour) => (
                <option key={hour.value} value={hour.value}>
                  {hour.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group controlId='formUserEnabled'>
            <Form.Check
              type='checkbox'
              label={formData.enabled ? 'Active' : 'Passive'}
              name='enabled'
              checked={formData.enabled}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFormData((prevData) => ({
                  ...prevData,
                  enabled: e.target.checked,
                }))
              }}
              className='mt-3 mb-3'
            />
          </Form.Group>
          <Modal.Footer>
            <Button variant='secondary' onClick={onClose}>
              Close
            </Button>
            <Button variant='primary' type='submit'>
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default AreaEditModal
