import React, { SyntheticEvent, useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import axios from 'axios'
import ResultViewer from '../../../utils/ResultViewer'
import BookingRequestEditModal from './BookingRequestEditModal'
import { useAuth } from '../../auth/core/AuthProvider'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import GroupBookingRequestEditModal from './GroupBookingRequestEditModal'

function GroupBookingRequestList() {
  const { user, loggedIn, hasValidRole } = useAuth()
  const location = useLocation()
  const [itemIdForUpdate, setItemIdForUpdate] = useState('')

  const [refreshFunction, setRefreshFunction] = useState<any>(() => {})

  const filters = ['']
  let bookingHeader = [
    { index: 1, type: 'field', key: 'id', title: 'ID', className: 'min-w-25px', sort: true },
    {
      index: 2,
      type: 'field',
      key: 'actions',
      title: 'Actions',
      className: 'min-w-25px',
    },
    {
      index: 3,
      type: 'field',
      key: 'areas',
      postProcess: (areas: any) => {
        return areas.map((area: any) => area.title).join(', ')
      },
      title: 'Areas',
      className: 'min-w-125px',
    },
    {
      index: 4,
      type: 'field',
      key: 'startsAt',
      title: 'Date',
      postProcess: (datetime: string) => {
        return moment(datetime).format('DD/MM/YYYY')
      },
      className: 'min-w-125px',
    },
    {
      index: 7,
      type: 'field',
      key: 'startsAt',
      title: 'Start Time',
      postProcess: (datetime: string) => {
        if (!datetime) {
          return 'N/A'
        } else {
          const date = new Date(datetime)
          const hour = date.getUTCHours()
          const minutes = date.getUTCMinutes()
          return `${hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
        }
      },
      className: 'min-w-125px',
    },
    {
      index: 8,
      type: 'field',
      key: 'endsAt',
      title: 'End Time',
      postProcess: (datetime: string) => {
        if (!datetime) {
          return 'N/A'
        } else {
          const date = new Date(datetime)
          const hour = date.getUTCHours()
          const minutes = date.getUTCMinutes()
          return `${hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
        }
      },
      className: 'min-w-125px',
    },
  ]

  let bookingUrl = '/admin/group_reservations'
  return (
    <>
      <ResultViewer
        title={'Bookings'}
        filter={filters}
        dataUrl={bookingUrl}
        editCallback={(id: any, refresh: Function) => {
          setRefreshFunction(() => refresh)
          if (id !== undefined && id !== '') {
            setItemIdForUpdate(id)
          }
        }}
        deleteCallback={(id: any, refresh: Function) => {
          Swal.fire({
            title: 'Warning',
            html: 'Are you want to delete this booking?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            customClass: {
              confirmButton: 'btn btn-danger',
              cancelButton: 'btn btn-primary',
            },
          }).then((result) => {
            if (result.isConfirmed) {
              axios.delete(bookingUrl + '/' + id).then(() => {
                refresh()
              })
            }
          })
        }}
        actionItems={[
          {
            text: 'Add New Group Reservation',
            class: 'btn btn-sm btn-primary me-2',
            icon: 'fa fa-users',
            onClick: function (event: SyntheticEvent, refresh: Function) {
              if (itemIdForUpdate !== undefined) {
                setItemIdForUpdate('add')
              }
              setRefreshFunction(() => refresh)
            },
          },
        ]}
        header={bookingHeader}
      />

      {itemIdForUpdate !== '' ? (
        <GroupBookingRequestEditModal
          show={itemIdForUpdate !== ''}
          itemIdForUpdate={itemIdForUpdate}
          onClose={() => {
            setItemIdForUpdate('')
            refreshFunction()
          }}
          refresh={(itemId: any) => {
            if (itemId !== undefined) {
              setItemIdForUpdate(itemId)
            }
            refreshFunction()
          }}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default GroupBookingRequestList
