import React, { SyntheticEvent, useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import axios from 'axios'
import ResultViewer from '../../../utils/ResultViewer'
import BookingRequestEditModal from './BookingRequestEditModal'
import { useAuth } from '../../auth/core/AuthProvider'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import GroupBookingRequestEditModal from './GroupBookingRequestEditModal'
import {
  fetchAreaIdsSelectList,
  fetchAreasList,
  fetchTrainers,
  getAreaOptionsForFilterSelect,
  getTrainerOptionsForFilterSelect,
} from '../../../utils/SelectListMethods'

function BookingRequestList() {
  const { user, loggedIn, token, hasValidRole } = useAuth()
  const location = useLocation()
  const [itemIdForUpdate, setItemIdForUpdate] = useState('')
  const [itemIdForGroupUpdate, setItemIdForGroupUpdate] = useState('')
  useEffect(() => {
    if (location.pathname.includes('/bookings/requests')) {
      const pathParts = location.pathname.split('/')
      const id = pathParts[pathParts.length - 1]
      if (!isNaN(Number(id))) {
        setItemIdForUpdate(id)
      }
    } else {
      setItemIdForUpdate('')
    }
  }, [location])

  const [refreshFunction, setRefreshFunction] = useState<any>(() => {})
  const [trainerOptions, setTrainersOptions] = useState<any>({})
  const [areaOptions, setAreaOptions] = useState<any>({})
  const getAreaOptions = async () => {
    getAreaOptionsForFilterSelect(token ?? '')
      .then((response) => {
        setAreaOptions(response)
      })
      .catch((error) => {
        console.error('Error fetching area list:', error)
      })
  }

  const getTrainersOptions = async () => {
    getTrainerOptionsForFilterSelect(token ?? '')
      .then((response) => {
        setTrainersOptions(response)
      })
      .catch((error) => {
        console.error('Error fetching branch list:', error)
      })
  }

  useEffect(() => {
    getAreaOptions()
    getTrainersOptions()
  }, [token])

  const filters = [
    {
      type: 'select',
      name: 'area',
      label: 'Area',
      options: areaOptions,
    },
    {
      type: 'select',
      name: 'trainer',
      label: 'Trainer',
      options: trainerOptions,
    },
    {
      type: 'daterange-before-after',
      name: 'date',
      label: 'Date Range',
    },
    {
      type: 'daterange-before-after',
      name: 'createdAt',
      label: 'Created At',
    },
    {
      type: 'select',
      name: 'status',
      label: 'Status',
      options: {
        approved: 'Approved',
        pending: 'Pending',
        rejected: 'Rejected',
      },
    },
  ]
  let bookingHeader = [
    { index: 1, type: 'field', key: 'id', title: 'ID', className: 'min-w-25px', sort: true },
    {
      index: 2,
      type: 'field',
      key: 'actions',
      title: 'Actions',
      className: 'min-w-25px',
    },
    {
      index: 3,
      type: 'object',
      key: 'trainer',
      subKey: 'name',
      title: 'Personal',
      className: 'min-w-125px',
    },
    {
      index: 4,
      type: 'object',
      key: 'user',
      subKey: 'fullname',
      title: 'User',
      className: 'min-w-125px',
    },
    {
      index: 5,
      type: 'object',
      key: 'area',
      title: 'Area',
      subKey: 'title',
      className: 'min-w-125px',
    },
    {
      index: 6,
      type: 'field',
      key: 'date',
      title: 'Date',
      postProcess: (datetime: string) => {
        return datetime && datetime !== 'N/A' ? moment(datetime).utc().format('DD/MM/YYYY') : 'N/A'
      },
      className: 'min-w-125px',
      sort: true,
    },
    {
      index: 7,
      type: 'field',
      key: 'startTime',
      title: 'Start Time',
      postProcess: (datetime: string) => {
        if (!datetime) {
          return 'N/A'
        } else {
          const date = new Date(datetime)
          const hour = date.getUTCHours()
          const minutes = date.getUTCMinutes()
          return `${hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
        }
      },
      className: 'min-w-125px',
    },
    {
      index: 8,
      type: 'field',
      key: 'endTime',
      title: 'End Time',
      postProcess: (datetime: string) => {
        if (!datetime) {
          return 'N/A'
        } else {
          const date = new Date(datetime)
          const hour = date.getUTCHours()
          const minutes = date.getUTCMinutes()
          return `${hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
        }
      },
      className: 'min-w-125px',
    },
    {
      index: 9,
      type: 'field',
      key: 'status',
      title: 'Status',
      className: 'min-w-125px',
      postProcess: (status: string) => {
        return status === 'Approved' ? (
          <span className='badge badge-primary'>Approved</span>
        ) : status === 'Rejected' ? (
          <span className='badge badge-danger'>Rejected</span>
        ) : (
          <span className='badge badge-warning'>Pending</span>
        )
      },
    },

    {
      index: 10,
      type: 'object',
      key: 'user',
      subKey: 'email',
      title: 'Email',
      className: 'min-w-25px',
      postProcess: (email: string) => {
        return (
          <button
            className='btn btn-sm btn-primary'
            onClick={() => (window.location.href = `mailto:${email}`)}
            disabled={!email}
          >
            <i className='fa fa-envelope'></i>
          </button>
        )
      },
    },
    {
      index: 11,
      type: 'object',
      key: 'user',
      subKey: 'address',
      title: 'Phone',
      className: 'min-w-125px',
      postProcess: (address: { phone?: string }) => {
        let phone = address?.phone ?? ''
        return (
          <button
            className='btn btn-sm btn-primary'
            onClick={() => (window.location.href = `tel:${phone}`)}
            disabled={!phone}
          >
            <i className='fa fa-phone'></i>
          </button>
        )
      },
    },
    {
      index: 12,
      type: 'object',
      key: 'createdBy',
      subKey: 'createdAt',
      title: 'Created At',
      className: 'min-w-125px',
      postProcess: (datetime: string) => {
        return datetime && datetime !== 'N/A'
          ? moment(datetime).utc().format('DD.MM.YYYY HH:mm')
          : 'N/A'
      },
      sort: true,
    },
    {
      index: 13,
      type: 'object',
      key: 'createdBy',
      subKey: 'updatedAt',
      title: 'Last Updated At',
      className: 'min-w-125px',
      postProcess: (datetime: string) => {
        return datetime && datetime !== 'N/A'
          ? moment(datetime).utc().format('DD.MM.YYYY HH:mm')
          : 'N/A'
      },
      sort: true,
    },
  ]

  let bookingUrl = '/admin/bookings'
  if (hasValidRole === 2) {
    bookingUrl = '/trainer/bookings'
    bookingHeader = bookingHeader.filter((header) => ![3].includes(header.index))
  }

  return (
    <>
      <ResultViewer
        title={'Bookings'}
        filter={filters}
        dataUrl={bookingUrl}
        editCallback={(id: any, refresh: Function) => {
          setRefreshFunction(() => refresh)
          if (id !== undefined && id !== '') {
            setItemIdForUpdate(id)
          }
        }}
        deleteCallback={(id: any, refresh: Function) => {
          Swal.fire({
            title: 'Warning',
            html: 'Are you want to delete this booking?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            customClass: {
              confirmButton: 'btn btn-danger',
              cancelButton: 'btn btn-primary',
            },
          }).then((result) => {
            if (result.isConfirmed) {
              axios.delete(bookingUrl + '/' + id).then(() => {
                refresh()
              })
            }
          })
        }}
        actionItems={[
          {
            text: 'Add New Group Reservation',
            class: 'btn btn-sm btn-primary me-2',
            icon: 'fa fa-users',
            onClick: function (event: SyntheticEvent, refresh: Function) {
              if (itemIdForGroupUpdate !== undefined) {
                setItemIdForGroupUpdate('add')
              }
              setRefreshFunction(() => refresh)
            },
          },
          {
            text: 'Add New Booking',
            class: 'btn btn-sm btn-primary',
            icon: 'fa fa-golf-ball',
            onClick: function (event: SyntheticEvent, refresh: Function) {
              if (itemIdForUpdate !== undefined) {
                setItemIdForUpdate('add')
              }
              setRefreshFunction(() => refresh)
            },
          },
        ]}
        header={bookingHeader}
        search={{
          name: 'search',
          label: '',
          type: 'text',
          options: null,
          placeholder: 'Search',
        }}
      />
      {itemIdForUpdate !== '' ? (
        <BookingRequestEditModal
          show={itemIdForUpdate !== ''}
          itemIdForUpdate={itemIdForUpdate}
          onClose={() => {
            setItemIdForUpdate('')
            refreshFunction()
          }}
          refresh={(itemId: any) => {
            if (itemId !== undefined) {
              setItemIdForUpdate(itemId)
            }
            refreshFunction()
          }}
        />
      ) : (
        <></>
      )}
      {itemIdForGroupUpdate !== '' ? (
        <GroupBookingRequestEditModal
          show={itemIdForGroupUpdate !== ''}
          itemIdForUpdate={itemIdForGroupUpdate}
          onClose={() => {
            setItemIdForGroupUpdate('')
            refreshFunction()
          }}
          refresh={(itemId: any) => {
            if (itemId !== undefined) {
              setItemIdForGroupUpdate(itemId)
            }
            refreshFunction()
          }}
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default BookingRequestList
