import React, { SyntheticEvent, useState } from 'react'
import UserEditModal from './UserEditModal'
import axios from 'axios'
import Swal from 'sweetalert2'
import UsersResultViewer from './UsersResultViewer'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import moment from 'moment'
function UsersList() {
  const location = useLocation()
  const [itemIdForUpdate, setItemIdForUpdate] = useState('')
  useEffect(() => {
    if (location.pathname.includes('/users/list')) {
      const pathParts = location.pathname.split('/')
      const id = pathParts[pathParts.length - 1]
      if (!isNaN(Number(id))) {
        setItemIdForUpdate(id)
      }
    } else {
      setItemIdForUpdate('')
    }
  }, [location])

  const [refreshFunction, setRefreshFunction] = useState<any>(() => {})
  let usersHeader = [
    { index: 1, type: 'field', key: 'id', title: 'ID', className: 'min-w-25px', sort: true },
    { index: 2, type: 'field', key: 'name', title: 'Name', className: 'min-w-125px', sort: true },
    {
      index: 3,
      type: 'field',
      key: 'surname',
      title: 'Surname',
      className: 'min-w-125px',
      sort: true,
    },
    { index: 4, type: 'field', key: 'email', title: 'Email', className: 'min-w-125px' },
    { index: 5, type: 'field', key: 'roles', title: 'Role', className: 'min-w-125px' },
    {
      index: 6,
      type: 'object',
      key: 'membershipPackage',
      subKey: 'name',
      title: 'Membership Package',
      className: 'min-w-125px',
    },
    {
      index: 7,
      type: 'field',
      key: 'membershipActive',
      title: 'Membership Status',
      className: 'min-w-150px',
      postProcess: (value: any) => (
        <span className={`badge ${value === true ? 'bg-primary' : 'bg-danger'}`}>
          {value === true ? 'Active' : 'Passive'}
        </span>
      ),
    },
    {
      index: 8,
      type: 'field',
      key: 'membershipExpiresAt',
      title: 'Membership Expire Date',
      className: 'min-w-200px',
      postProcess: (value: any) => {
        return value && value !== 'N/A' ? moment(value).utc().format('DD/MM/YYYY') : 'N/A'
      },
    },
    {
      index: 9,
      type: 'field',
      key: 'status',
      title: 'User Status',
      className: 'min-w-125px',
      postProcess: (value: any) => {
        return value === 'Active' ? (
          <span className='badge badge-primary'>Active</span>
        ) : value === 'Blocked' ? (
          <span className='badge badge-warning'>Blocked</span>
        ) : value === 'Banned' ? (
          <span className='badge badge-danger'>Banned</span>
        ) : (
          value
        )
      },
    },
    { index: 8, type: 'field', key: 'actions', title: 'Actions', className: 'min-w-125px' },
  ]

  // select, daterange,  text
  const statusOptions = {
    Active: 'Active',
    Blocked: 'Blocked',
    Banned: 'Banned',
  }

  const rolesOptions = {
    ROLE_SUPER_ADMIN: 'Super Admin',
    ROLE_ADMIN: 'Admin',
    ROLE_USER: 'User',
    ROLE_COACH: 'Coach',
    ROLE_PHYSIO: 'Physio',
    ROLE_PERSONAL_TRAINER: 'Personal Trainer',
  }

  let [membershipPackageOptions, setMembershipPackageOptions] = useState<any>({})

  const fetchMembershipPackages = () => {
    axios
      .get('/admin/membership_packages', {
        params: {
          page: 1,
          pageSize: 100,
        },
      })
      .then((response) => {
        const packages = response.data['hydra:member']
        const options: { [key: string]: string } = {}
        packages.forEach((pkg: any) => {
          options[pkg.id] = pkg.name
        })
        setMembershipPackageOptions(options)
      })
  }

  useEffect(() => {
    fetchMembershipPackages()
  }, [])

  const filters = [
    {
      name: 'name',
      label: 'Name',
      type: 'text',
      options: null,
      placeholder: 'Enter name',
    },
    {
      name: 'surname',
      label: 'Surname',
      type: 'text',
      options: null,
      placeholder: 'Enter surname',
    },
    {
      name: 'email',
      label: 'Email',
      type: 'text',
      options: null,
      placeholder: 'Enter email',
    },
    {
      name: 'roles',
      label: 'Roles',
      type: 'select',
      options: rolesOptions,
    },
    {
      name: 'membershipPackage',
      label: 'Membership Package',
      type: 'select',
      multiple: true,
      options: membershipPackageOptions,
    },
    {
      name: 'exists[familyOwner]',
      label: 'Family Owner',
      type: 'select',
      options: {
        true: 'Yes',
        false: 'No',
      },
    },
    {
      name: 'exists[familyMambers]',
      label: 'Family Members',
      type: 'select',
      options: {
        true: 'Yes',
        false: 'No',
      },
    },
    {
      name: 'freeMember',
      label: 'Free Member',
      type: 'select',
      options: {
        true: 'Yes',
        false: 'No',
      },
    },
    {
      name: 'status',
      label: 'User Status',
      type: 'select',
      options: statusOptions,
    },
  ]

  return (
    <>
      <UsersResultViewer
        title={'Users'}
        dataUrl={'/admin/users'}
        filter={filters}
        editCallback={(id: any, refresh: Function) => {
          setRefreshFunction(() => refresh)

          setItemIdForUpdate(id)
        }}
        deleteCallback={(id: any, refresh: Function) => {
          Swal.fire({
            title: 'Warning',
            html: 'Are you want to delete this user?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            customClass: {
              confirmButton: 'btn btn-danger',
              cancelButton: 'btn btn-primary',
            },
          }).then((result) => {
            if (result.isConfirmed) {
              axios.delete('admin/users/' + id).then(() => {
                refresh()
              })
            }
          })
        }}
        actionItems={[
          {
            text: 'Add New User',
            class: 'btn btn-sm btn-primary',
            icon: 'fa fa-user-plus',
            onClick: function (event: SyntheticEvent, refresh: Function) {
              if (itemIdForUpdate !== undefined && itemIdForUpdate !== '') {
                setItemIdForUpdate(itemIdForUpdate)
              }
            },
          },
        ]}
        header={usersHeader}
        search={{
          name: 'search',
          label: '',
          type: 'text',
          options: null,
          placeholder: 'Search',
        }}
      />
      {itemIdForUpdate !== '' ? (
        <UserEditModal
          itemIdForUpdate={itemIdForUpdate}
          show={itemIdForUpdate !== ''}
          onHide={() => {
            setItemIdForUpdate('')
          }}
          refreshFunction={refreshFunction}
        />
      ) : null}
    </>
  )
}

export default UsersList
