import axios from 'axios'

const token = localStorage.getItem('@auth/token')

export const fetchBranchList = async (token: string) => {
  try {
    const response = await axios.get('/admin/branches', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    let options = []
    if (response.data['hydra:member']) {
      options = response.data['hydra:member'].map((branch: any) => ({
        value: branch['@id'],
        label: branch.title,
      }))
    }
    return options
  } catch (error) {
    console.error('Error fetching branch list:', error)
    throw error
  }
}

export const fetchClubList = async (token: string) => {
  try {
    const response = await axios.get('/admin/clubs', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    let options = []
    if (response.data['hydra:member']) {
      options = response.data['hydra:member'].map((club: any) => ({
        value: club['@id'],
        label: club.name,
      }))
    }
    return options
  } catch (error) {
    console.error('Error fetching club list:', error)
    throw error
  }
}

export const fetchLaneList = async (token: string) => {
  try {
    const response = await axios.get('/admin/lanes', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    let options = []
    if (response.data['hydra:member']) {
      options = response.data['hydra:member'].map((lane: any) => ({
        value: lane['@id'],
        label: lane.title,
      }))
    }
    return options
  } catch (error) {
    console.error('Error fetching lane list:', error)
    throw error
  }
}

// Trainer rolüne göre filtreleme kısıtı eklenecek.
export const fetchTrainersList = async (token: string) => {
  try {
    const response = await axios.get('/admin/users', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    let options = []
    if (response.data['hydra:member']) {
      options = response.data['hydra:member'].map((user: any) => ({
        value: user['id'],
        label: user.name,
      }))
    }
    return options
  } catch (error) {
    console.error('Error fetching trainer list:', error)
    throw error
  }
}

export const fetchAreaTypesList = async (token: string) => {
  try {
    const response = await axios.get('/admin/area_types', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    let options = []
    if (response.data['hydra:member']) {
      options = response.data['hydra:member'].map((area_type: any) => ({
        value: area_type['@id'],
        label: area_type.name,
      }))
    }
    return options
  } catch (error) {
    console.error('Error fetching area type list:', error)
    throw error
  }
}

export const fetchAreasList = async (token: string) => {
  try {
    const response = await axios.get('/admin/areas', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    let options = []
    if (response.data['hydra:member']) {
      options = response.data['hydra:member'].map((area: any) => ({
        value: area['@id'],
        label: area.title,
      }))
    }
    return options
  } catch (error) {
    console.error('Error fetching area list:', error)
    throw error
  }
}

export const fetchUsersList = async (token: string) => {
  try {
    const response = await axios.get('/admin/users', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    let options = []
    if (response.data['hydra:member']) {
      options = response.data['hydra:member'].map((user: any) => ({
        value: user['@id'],
        label: user.name,
      }))
    }
    return options
  } catch (error) {
    console.error('Error fetching user list:', error)
    throw error
  }
}

export const fetchTrainers = async (token: string) => {
  try {
    const response = await axios.get('/trainers', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    let options = []
    if (response.data) {
      options = response.data.map((user: any) => ({
        value: '/api/admin/users/' + user['id'],
        label: user.name,
      }))
    }
    return options
  } catch (error) {
    console.error('Error fetching trainer list:', error)
    throw error
  }
}

export const fetchUsersForTrainers = async (token: string) => {
  try {
    const response = await axios.get('/trainer/users?pageSize=50', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    let options = []
    if (response.data['hydra:member']) {
      options = response.data['hydra:member'].map((user: any) => ({
        value: user['@id'],
        label: user.name,
      }))
    }
    return options
  } catch (error) {
    console.error('Error fetching trainer list:', error)
    throw error
  }
}

export const fetchAreasForTrainers = async (token: string) => {
  try {
    const response = await axios.get('/trainer/areas', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    let options = []
    if (response.data['hydra:member']) {
      options = response.data['hydra:member'].map((area: any) => ({
        value: area['@id'],
        label: area.title,
      }))
    }
    return options
  } catch (error) {
    console.error('Error fetching trainer list:', error)
    throw error
  }
}

export const fetchMembershipPackageList = async (token: string) => {
  try {
    const response = await axios.get('/admin/membership_packages', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    let options = []
    if (response.data['hydra:member']) {
      options = response.data['hydra:member'].map((membershipPackage: any) => ({
        value: membershipPackage['@id'],
        label: membershipPackage.name,
      }))
    }
    return options
  } catch (error) {
    console.error('Error fetching membership package list:', error)
    throw error
  }
}

export const fetchUsersAutocomplete = async (search: string, token: string, role: string) => {
  try {
    const response = await axios.get(`/${role}/users?name=${search}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    let options = []
    if (response.data['hydra:member']) {
      options = response.data['hydra:member'].map((user: any) => ({
        value: user['@id'],
        label: user.fullname
          ? `${user.fullname} (${user.membershipPackage ? user.membershipPackage.name : 'N/A'})`
          : `${user.name} ${user.surname} (${
              user.membershipPackage ? user.membershipPackage.name : 'N/A'
            })`,
      }))
    }
    return options
  } catch (error) {
    console.error('Error fetching user list:', error)
    throw error
  }
}

export const fetchTrainersListByRole = async (token: string, role?: string) => {
  try {
    const response = await axios.get('/trainers', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    let options = []
    if (response.data) {
      options = response.data.map((user: any) => ({
        value: user['id'],
        label: user.name,
      }))
    }
    return options
  } catch (error) {
    console.error('Error fetching trainer list:', error)
    throw error
  }
}

export const fetchAreaIdsSelectList = async (token: string) => {
  try {
    const response = await axios.get('/trainer/areas', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    let options = []
    if (response.data['hydra:member']) {
      options = response.data['hydra:member'].map((area: any) => ({
        value: area['id'],
        label: area.title,
      }))
    }
    return options
  } catch (error) {
    console.error('Error fetching area list:', error)
    throw error
  }
}

export const getAreaOptionsForFilterSelect = async (token: string) => {
  try {
    const response = await axios.get('/admin/areas', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    let options = {}
    if (response.data['hydra:member']) {
      options = response.data['hydra:member'].reduce((acc: any, area: any) => {
        acc[area.id] = area.title
        return acc
      }, {})
    }
    return options
  } catch (error) {
    console.error('Error fetching area list:', error)
    throw error
  }
}

export const getTrainerOptionsForFilterSelect = async (token: string) => {
  try {
    const response = await axios.get('/trainers', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    let options = {}
    if (response.data) {
      options = response.data.reduce((acc: any, user: any) => {
        acc[user.id] = user.fullname + ' (' + user.email + ')'
        return acc
      }, {})
    }
    return options
  } catch (error) {
    console.error('Error fetching trainer list:', error)
    throw error
  }
}

export const fetchMembershipPackageOptions = async (token: string) => {
  try {
    const response = await axios.get('/admin/membership_packages', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    let options = []
    if (response.data['hydra:member']) {
      options = response.data['hydra:member'].reduce((acc: any, memberPackage: any) => {
        acc[memberPackage.id] = memberPackage.name
        return acc
      }, {})
    }
    return options
  } catch (error) {
    console.error('Error fetching membership package list:', error)
    throw error
  }
}

export const fetchUsersOptions = async (token: string) => {
  try {
    const response = await axios.get('/admin/users', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    let options = []
    if (response.data['hydra:member']) {
      options = response.data['hydra:member'].reduce((acc: any, user: any) => {
        acc[user.id] = user.fullname + ' (' + user.email + ')'
        return acc
      }, {})
    }
    return options
  } catch (error) {
    console.error('Error fetching user list:', error)
    throw error
  }
}
