import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import {
  fetchUsersList,
  fetchBranchList,
  fetchTrainers,
  fetchAreasList,
  fetchUsersForTrainers,
  fetchAreasForTrainers,
  fetchUsersAutocomplete,
} from '../../../utils/SelectListMethods'
import { useAuth } from '../../auth/core/AuthProvider'
import Swal from 'sweetalert2'

interface GroupBookingEditModalProps {
  itemIdForUpdate: string
  show: boolean
  onClose: () => void
  refresh: Function
}

interface FormDataType {
  startsAt: string
  endsAt: string
  areas: string[]
}

interface AreaOption {
  value: string
  label: string
}

const GroupBookingRequestEditModal: React.FC<GroupBookingEditModalProps> = ({
  itemIdForUpdate,
  show,
  onClose,
  refresh,
}) => {
  const [formData, setFormData] = useState<FormDataType>({
    startsAt: '',
    endsAt: '',
    areas: [],
  })

  const { token } = useAuth()
  const [areaOptions, setAreaOptions] = useState<AreaOption[]>([])
  const [date, setDate] = useState<Date | null>(null)
  const [startTime, setStartTime] = useState<Date>(new Date())
  const [endTime, setEndTime] = useState<Date>(new Date())

  // Saat slotlarını oluştur (06:00-22:00 arası)
  const generateTimeSlots = () => {
    const slots = []
    for (let i = 6; i <= 22; i++) {
      const hour = i.toString().padStart(2, '0')
      slots.push(`${hour}:00`)
    }
    return slots
  }

  const [timeSlots] = useState(generateTimeSlots())

  const formatDate = (date: Date): string => {
    return date.toISOString().split('T')[0]
  }

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = new Date(e.target.value)
    setDate(selectedDate)

    // Reset time selections when date changes
    setFormData((prev) => ({
      ...prev,
      startsAt: '',
      endsAt: '',
    }))

    setStartTime(selectedDate)
    setEndTime(selectedDate)
  }

  const handleTimeChange = (
    timeType: 'startsAt' | 'endsAt',
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedTime = e.target.value

    if (date) {
      const [hours, minutes] = selectedTime.split(':')
      const newDateTime = new Date(date)
      newDateTime.setUTCHours(parseInt(hours), parseInt(minutes))

      if (timeType === 'startsAt') {
        setStartTime(newDateTime)

        // Automatically set end time to one hour after start time
        const endHour = parseInt(hours) + 1
        if (endHour <= 22) {
          const nextHour = endHour.toString().padStart(2, '0')
          const nextTime = `${nextHour}:00`

          const newEndDateTime = new Date(date)
          newEndDateTime.setUTCHours(endHour, 0)

          setEndTime(newEndDateTime)
          setFormData((prev) => ({
            ...prev,
            startsAt: selectedTime,
            endsAt: nextTime,
          }))
        } else {
          setFormData((prev) => ({
            ...prev,
            startsAt: selectedTime,
          }))
        }
      } else {
        setEndTime(newDateTime)
        setFormData((prev) => ({
          ...prev,
          endsAt: selectedTime,
        }))
      }
    }
  }

  const fetchSingleData = async () => {
    if (itemIdForUpdate !== 'add') {
      try {
        const response = await axios.get(`/admin/group_reservations/${itemIdForUpdate}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        const data = response.data

        if (data.startsAt) {
          const startDateTime = new Date(data.startsAt)
          setStartTime(startDateTime)
          setDate(startDateTime)

          const hours = startDateTime.getUTCHours().toString().padStart(2, '0')
          setFormData((prev) => ({
            ...prev,
            startsAt: `${hours}:00`,
          }))
        }

        if (data.endsAt) {
          const endDateTime = new Date(data.endsAt)
          setEndTime(endDateTime)

          const hours = endDateTime.getUTCHours().toString().padStart(2, '0')
          setFormData((prev) => ({
            ...prev,
            endsAt: `${hours}:00`,
          }))
        }

        if (data.areas && Array.isArray(data.areas)) {
          const areaUrls = data.areas.map((area: any) => `/api/admin/areas/${area.id}`)
          setFormData((prev) => ({
            ...prev,
            areas: areaUrls,
          }))
        }
      } catch (error) {
        console.error('Error fetching data:', error)
        Swal.fire({
          title: 'Error',
          text: 'Error loading reservation data',
          icon: 'error',
          confirmButtonText: 'OK',
        })
      }
    } else {
      setFormData({
        startsAt: '',
        endsAt: '',
        areas: [],
      })
      setDate(null)
    }
  }

  const getAreaOptions = async () => {
    try {
      const response = await fetchAreasList(token ?? '')

      setAreaOptions(response)
    } catch (error) {
      console.error('Error fetching area list:', error)
      Swal.fire({
        title: 'Error',
        text: 'Error loading areas',
        icon: 'error',
        confirmButtonText: 'OK',
      })
    }
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (itemIdForUpdate === 'add') {
      await createData()
    } else {
      await updateData()
    }
  }

  const createData = async () => {
    if (!validateForm()) return

    const startDateTime = new Date(date!)
    const [startHour] = formData.startsAt.split(':')
    startDateTime.setUTCHours(parseInt(startHour), 0, 0)

    const endDateTime = new Date(date!)
    const [endHour] = formData.endsAt.split(':')
    endDateTime.setUTCHours(parseInt(endHour), 0, 0)

    const newData = {
      startsAt: startDateTime.toISOString(),
      endsAt: endDateTime.toISOString(),
      areas: formData.areas,
    }

    try {
      const response = await axios.post('/admin/group_reservations', newData, {
        headers: {
          Accept: 'application/ld+json',
          'Content-Type': 'application/ld+json',
          Authorization: `Bearer ${token}`,
        },
      })

      if (response.status === 200 || response.status === 201) {
        Swal.fire({
          title: 'Success',
          text: 'Reservation created successfully',
          icon: 'success',
          timer: 1500,
        })
        refresh(true)
        onClose()
      }
    } catch (error: any) {
      Swal.fire({
        title: 'Error',
        text: error.response?.data?.message || 'Error creating reservation',
        icon: 'error',
        confirmButtonText: 'OK',
      })
    }
  }

  const updateData = async () => {
    if (!validateForm()) return

    const startDateTime = new Date(date!)
    const [startHour] = formData.startsAt.split(':')
    startDateTime.setUTCHours(parseInt(startHour), 0, 0)

    const endDateTime = new Date(date!)
    const [endHour] = formData.endsAt.split(':')
    endDateTime.setUTCHours(parseInt(endHour), 0, 0)

    const updatedData = {
      startsAt: startDateTime.toISOString(),
      endsAt: endDateTime.toISOString(),
      areas: formData.areas,
    }

    try {
      const response = await axios.patch(
        `/admin/group_reservations/${itemIdForUpdate}`,
        updatedData,
        {
          headers: {
            Accept: 'application/ld+json',
            'Content-Type': 'application/merge-patch+json',
            Authorization: `Bearer ${token}`,
          },
        }
      )

      if (response.status === 200 || response.status === 201) {
        Swal.fire({
          title: 'Success',
          text: 'Reservation updated successfully',
          icon: 'success',
          confirmButtonText: 'OK',
          confirmButtonColor: '#004F44',
        })
        refresh(true)
        onClose()
      }
    } catch (error: any) {
      Swal.fire({
        title: 'Error',
        text: error.response?.data?.message || 'Error updating reservation',
        icon: 'error',
        confirmButtonText: 'OK',
      })
    }
  }

  const validateForm = (): boolean => {
    if (!date) {
      Swal.fire({
        title: 'Error',
        text: 'Please select a date',
        icon: 'error',
        confirmButtonText: 'OK',
      })
      return false
    }

    if (!formData.startsAt || !formData.endsAt) {
      Swal.fire({
        title: 'Error',
        text: 'Please select both start and end times',
        icon: 'error',
        confirmButtonText: 'OK',
      })
      return false
    }

    if (formData.areas.length === 0) {
      Swal.fire({
        title: 'Error',
        text: 'Please select at least one area',
        icon: 'error',
        confirmButtonText: 'OK',
      })
      return false
    }

    return true
  }

  useEffect(() => {
    getAreaOptions()
    if (show) {
      fetchSingleData()
    }
  }, [show])
  const handleDelete = async () => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'This group reservation will be permanently deleted.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#004F44',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    })

    if (result.isConfirmed) {
      try {
        await axios.delete(`/admin/group_reservations/${itemIdForUpdate}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        Swal.fire({
          title: 'Deleted!',
          text: 'Group reservation has been deleted.',
          icon: 'success',
          timer: 1500,
        })
        refresh(true)
        onClose()
      } catch (error: any) {
        Swal.fire({
          title: 'Error',
          text: error.response?.data?.message || 'Error deleting reservation',
          icon: 'error',
          confirmButtonText: 'OK',
        })
      }
    }
  }

  return (
    <Modal show={show} onHide={onClose} backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>
          {itemIdForUpdate === 'add' ? 'Add Group Reservation' : 'Edit Group Reservation'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className='mb-3' controlId='formDate'>
            <Form.Label>Date</Form.Label>
            <Form.Control
              type='date'
              value={date ? formatDate(date) : ''}
              onChange={handleDateChange}
              min={formatDate(new Date())}
            />
          </Form.Group>

          <div className='row mb-3'>
            <div className='col'>
              <Form.Group controlId='formStartTime'>
                <Form.Label>Start Time</Form.Label>
                <Form.Select
                  value={formData.startsAt || ''}
                  onChange={(e) => handleTimeChange('startsAt', e)}
                  disabled={!date}
                >
                  <option value=''>Select start time</option>
                  {timeSlots.map((time) => (
                    <option key={time} value={time} disabled={time === '22:00'}>
                      {time}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
            <div className='col'>
              <Form.Group controlId='formEndTime'>
                <Form.Label>End Time</Form.Label>
                <Form.Select
                  value={formData.endsAt || ''}
                  onChange={(e) => handleTimeChange('endsAt', e)}
                  disabled={!date || !formData.startsAt}
                >
                  <option value=''>Select end time</option>
                  {timeSlots.map((time) => {
                    const [startHour] = (formData.startsAt || '00:00').split(':')
                    const [currentHour] = time.split(':')
                    const isDisabled = parseInt(currentHour) <= parseInt(startHour)

                    return (
                      <option key={time} value={time} disabled={isDisabled}>
                        {time}
                      </option>
                    )
                  })}
                </Form.Select>
              </Form.Group>
            </div>
          </div>

          <Form.Group controlId='formArea'>
            <Form.Label>Areas</Form.Label>
            <div className='d-flex flex-wrap gap-2'>
              {areaOptions.map((area) => (
                <Button
                  key={area.value}
                  variant={formData.areas?.includes(area.value) ? 'primary' : 'outline-primary'}
                  onClick={(e) => {
                    e.preventDefault()
                    setFormData((prev) => ({
                      ...prev,
                      areas: prev.areas?.includes(area.value)
                        ? prev.areas.filter((a) => a !== area.value)
                        : [...(prev.areas || []), area.value],
                    }))
                  }}
                  className='area-button'
                  style={{
                    backgroundColor: formData.areas?.includes(area.value) ? '#004F44' : 'white',
                    borderColor: '#004F44',
                    color: formData.areas?.includes(area.value) ? 'white' : '#004F44',
                  }}
                >
                  {area.label}
                </Button>
              ))}
            </div>
            <small className='text-muted d-block mt-2'>Click on areas to select/deselect</small>
          </Form.Group>

          <div className='d-flex justify-content-between align-items-center mt-3'>
            <div>
              {itemIdForUpdate !== 'add' && (
                <Button variant='danger' onClick={handleDelete} className='me-2'>
                  Delete
                </Button>
              )}
            </div>

            <div>
              <Button variant='secondary' onClick={onClose} className='me-2'>
                Cancel
              </Button>
              <Button
                type='submit'
                style={{
                  backgroundColor: '#004F44',
                  borderColor: '#004F44',
                }}
              >
                Save changes
              </Button>
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default GroupBookingRequestEditModal
